// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'

import map from 'lodash/map'
import filter from 'lodash/filter'
import endsWith from 'lodash/endsWith'
import groupBy from 'lodash/groupBy'
import isInteger from 'lodash/isInteger'
import noop from 'lodash/noop'
import head from 'lodash/head'
import last from 'lodash/last'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { StaticQuery, graphql } from 'gatsby'
import { navigate } from 'gatsby-plugin-intl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../link'
import '../link/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- Query
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    allResources(sort: { order: ASC, fields: position }) {
      edges {
        node {
          title {
            lang
            content
          }
          position
          routeSlug
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** FooterFX */
class FooterFX extends React.PureComponent {
  /** [constructor description] */
  constructor(props) {
    super(props)

    const { uri, nodes, nodePositions } = this.props

    const match = filter(nodes, ({ routeSlug }) => endsWith(uri, routeSlug))
    const lastNode = last(nodes)
    const node =
      match.length > 0 ? match[0] : { routeSlug: '/', intlTitle: 'Home' }
    const currentNodePosition =
      match.length > 0 ? Math.floor(match[0].position) : -1

    let prev =
      match.length > 0
        ? head(nodePositions) !== currentNodePosition
          ? filter(
              nodes,
              ({ position }) => position === currentNodePosition - 1
            )[0]
          : null
        : null

    let next =
      match.length > 0
        ? last(nodePositions) !== currentNodePosition
          ? filter(
              nodes,
              ({ position }) => position === currentNodePosition + 1
            )[0]
          : null
        : null

    if (prev === null || isUndefined(prev) === true) {
      prev = { routeSlug: '/about', intlTitle: 'About' }
    }

    if (next === null || isUndefined(next) === true) {
      if (node.routeSlug === lastNode.routeSlug) {
        next = { routeSlug: '/about', intlTitle: 'About' }
      } else {
        next = nodes[0]
      }
    }

    this.state = { node, prev, next }

    this.leftFx = this.leftFx.bind(this)
    this.rightFx = this.rightFx.bind(this)
  }

  /** [componentDidMount description] */
  componentDidMount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [componentWillUnmount description] */
  componentWillUnmount() {
    document.addEventListener('keydown', this.leftFx, false)
    document.addEventListener('keydown', this.rightFx, false)
  }

  /** [leftFx description] */
  leftFx(event) {
    if (event.keyCode === 37) {
      const { prev } = this.state

      if (isNull(prev) === false) {
        navigate(prev.routeSlug)
      }
    }
  }

  /** [rightFx description] */
  rightFx(event) {
    if (event.keyCode === 39) {
      const { next } = this.state

      if (isNull(next) === false) {
        navigate(next.routeSlug)
      }
    }
  }

  /** [render description] */
  render() {
    const { uri } = this.props
    const { node, prev, next } = this.state

    return (
      <footer>
        <div className="prev-next">
          {prev.routeSlug === '/about' && (
            <Link
              to={prev.routeSlug}
              className="prev"
              aria-label="Previous"
              title={prev.intlTitle}
            >
              {prev.intlTitle}
            </Link>
          )}
          {prev.routeSlug !== '/about' && (
            <Link
              to={prev.routeSlug}
              className="prev"
              aria-label="Previous"
              title={prev.intlTitle}
            >
              ← <span>{prev.intlTitle}</span>
            </Link>
          )}
          <Link
            to={node.routeSlug}
            className="top"
            aria-label="Top"
            style={{ color: 'unset' }}
          >
            Top
          </Link>
          <Link
            to="/"
            className="cover-page"
            aria-label="Cover"
            style={{ color: 'unset' }}
          >
            Cover
          </Link>
          {next.routeSlug === '/about' && (
            <Link
              to={next.routeSlug}
              className="next"
              aria-label="Next"
              title={next.intlTitle}
            >
              {next.intlTitle}
            </Link>
          )}
          {next.routeSlug !== '/about' && (
            <Link
              to={next.routeSlug}
              className="next"
              aria-label="Next"
              title={next.intlTitle}
            >
              <span>{next.intlTitle}</span> →
            </Link>
          )}
        </div>
      </footer>
    )
  }
}

/** Footer */
const Footer = React.memo(({ uri, lang }) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const nodes = map(data.allResources.edges, 'node').slice(0, -1)
      const intlNodes = map(nodes, (node) => ({
        ...node,
        intlTitle: filter(node.title, ['lang', lang])[0].content,
      }))
      const nodePositions = []
      map(groupBy(map(intlNodes, 'position'), Math.floor), (a) =>
        map(a, (v) => (isInteger(v) ? nodePositions.push(v) : noop()))
      )

      return (
        <FooterFX uri={uri} nodes={intlNodes} nodePositions={nodePositions} />
      )
    }}
  />
))

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default Footer
