// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { Spring, animated } from 'react-spring'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** [description] */
const OneMany = ({ onClick, isActive }) => (
  <svg width="54" height="54" viewBox="0 0 54 54" onClick={onClick}>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 11, cy: 11, r: 11, fill: '#8fbcbb' }}
      to={{ cx: 27, cy: 27, r: 27, fill: '#8fbcbb' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 40, cy: 11, r: 11, fill: '#88c0d0' }}
      to={{ cx: 27, cy: 27, r: 27, fill: '#8fbcbb' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 11, cy: 40, r: 11, fill: '#81a1c1' }}
      to={{ cx: 27, cy: 27, r: 27, fill: '#8fbcbb' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
    <Spring
      native
      reset
      reverse={isActive}
      from={{ cx: 40, cy: 40, r: 11, fill: '#5e81ac' }}
      to={{ cx: 27, cy: 27, r: 27, fill: '#8fbcbb' }}
    >
      {(props) => (
        <animated.circle
          fill={props.fill}
          cx={props.cx}
          cy={props.cy}
          r={props.r}
        />
      )}
    </Spring>
  </svg>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default OneMany
